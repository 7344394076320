<template>
    <div>
      <div class="row page-title align-items-center">
        <div class="col-sm-4 col-xl-6">
          <h4 class="mb-1 mt-0">Contact Import Files</h4>
        </div>
      </div>
      <div v-if="!isLoading && uploads" class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row mb-md-2">
                <div class="col-sm-12 col-md-6">
                  <div class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      Show&nbsp;
                      <b-form-select
                        v-model="datatable.queries.per_page"
                        class="ml-1 mr-1"
                        size="sm"
                        :options="[10, 15, 25, 50, 100]"
                        @change="getAllUploads"
                      ></b-form-select
                      >&nbsp;entries
                    </label>
                  </div>
                </div>
              </div>
              <div class="table-responsive mb-0">
                <b-table
                  :items="uploads.data"
                  :fields="datatable.columns"
                  :sort-by.sync="datatable.queries.sort_by"
                  :sort-desc.sync="datatable.queries.sort_desc"
                  :per-page="datatable.queries.per_page"
                >
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                  <template v-slot:cell(uploaded_at)="data">
                    {{ data.item.created_at | moment('MM-DD-YYYY hh:mm A') }}
                  </template>
                  <template v-slot:cell(size)="data">
                    {{ parseInt(data.item.size / 1024) }} KB
                  </template>
                  <template v-slot:cell(actions)="data">
                    <a
                      class="btn btn-sm btn-primary"
                      title="Download File"
                      :href="data.item.download_url"
                      download
                    >
                      <i class="uil uil-download-alt"></i>
                    </a>
                  </template>
                </b-table>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <div class="float-left mt-2">
                    Total {{ uploads.meta.total }} notifications
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                        v-model="datatable.queries.page"
                        :total-rows="uploads.meta.total"
                        :per-page="datatable.queries.per_page"
                        @change="onPageChanged"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="text-center">
        <b-spinner></b-spinner>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        datatable: {
          columns: [
            { key: 'agency_name', label: 'Agency Name' },
            { key: 'business_name', label: 'Business Name' },
            { key: 'file_name', label: 'File Name' },
            { key: 'number_of_contacts', label: 'Number Of Contacts' },
            { key: 'size', label: 'Size' },
            { key: 'uploaded_at', label: 'Uploaded At' },
            { key: 'actions' },
          ],
          queries: {
            per_page: 10,
            page: 1,
          },
        },
        uploads: null,
        isLoading: false,
      }
    },
  
    computed: {
      user() {
        return this.$store.getters['auth/user']
      },
    },
  
    mounted() {
      this.getAllUploads()
    },
  
    methods: {
      onPageChanged(page) {
        this.datatable.queries.page = page
        this.getAllUploads()
      },

      getAllUploads() {
        this.isLoading = true

        this.$store.dispatch('contact/getAllUploads', this.datatable.queries)
          .then(uploads => {
            this.uploads = uploads
            this.isLoading = false
          })
          .catch(() => {
            this.isLoading = false
          })
      },
    },
  }
  </script>
  